import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/index.js";
import BG from '../components/bg';
import styled from 'styled-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BG mdxType="BG" />
    <div style={{
      maxWidth: '650px',
      margin: '0px auto 10rem auto'
    }}>
      <Title mdxType="Title">Pegasys Labs Trademark Guidelines</Title>
      <p>{`Pegasys Labs distributed the open source Pegasys protocol and distributes other software. While our code is in part available to download, review, and improve under open-source and source-available software licenses, none of our licenses include a license to use our trademarks. Proper use of our trademarks is essential to inform users whether or not Pegasys Labs stands behind a product or service. When using Pegasys Labs`}{`’`}{` trademarks, you must comply with these Pegasys Labs Trademark Guidelines. Just like other projects that develop open source software, we must enforce our trademark rights to protect our users.`}</p>
      <p>{`This policy covers all of our trademarks and services marks, whether they are registered or not, including, among others:`}</p>
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Our word trademarks and service marks: POLLUM®, PSYS™, PEGASYS®, and Pegasys Labs™ (`}{`”`}{`Pegasys Labs wordmarks`}{`”`}{`).`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pegasys `}<a parentName="p" {...{
              "href": "./about/#brand"
            }}>{`logos`}</a>{`.`}</p>
        </li>
      </ol>
      <p>{`If you want to report misuse of a Pegasys Labs or Pegasys trademark, please contact us at `}<a parentName="p" {...{
          "href": "mailto:contact@pegasys.fi"
        }}>{`contact@pegasys.fi`}</a>{`.`}</p>
      <h1 {...{
        "id": "acceptable-uses",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#acceptable-uses",
          "aria-label": "acceptable uses permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Acceptable Uses`}</h1>
      <p>{`You may do the following `}<em parentName="p">{`without`}</em>{` receiving specific permission from Pegasys Labs:`}</p>
      <ul>
        <li parentName="ul">{`Use Pegasys Labs wordmarks in text to truthfully refer to and/or link to publicly available, unmodified Pegasys smart contracts, protocols, interfaces, programs, products, services and technologies (`}{`”`}{`Pegasys Labs software`}{`”`}{`).`}</li>
        <li parentName="ul">{`Use the Pegasys Labs wordmarks to truthfully describe modified versions of Pegasys Labs software that you may create or make available. For example, you may say `}{`”`}{`This software is derived from Pegasys Labs software.`}{`”`}{` or `}{`”`}{`This service uses software derived from Pegasys Labs software.`}{`”`}</li>
        <li parentName="ul">{`Use the Pegasys Labs logo in software or aggregators that integrate with Pegasys Labs software to truthfully refer to, and, where possible, link to the applicable Pegasys Labs software hosted on the Syscoin blockchain.`}</li>
        <li parentName="ul">{`Use Pegasys Labs wordmarks to clearly signal to users that there is no affiliation with or endorsement by Pegasys Labs.`}</li>
        <li parentName="ul">{`Follow the terms of the open source licenses for Pegasys Labs software.`}</li>
      </ul>
      <h2 {...{
        "id": "when-allowed-how-can-i-use-a-pegasys-labs-trademark",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#when-allowed-how-can-i-use-a-pegasys-labs-trademark",
          "aria-label": "when allowed how can i use a pegasys labs trademark permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a><strong parentName="h2">{`When allowed, how can I use a Pegasys Labs trademark?`}</strong></h2>
      <ul>
        <li parentName="ul">{`You must include a trademark attribution notice at the first or most prominent mention of the mark on a webpage, document or documentation, such as: `}{`”`}{`[Pegasys Labs trademark]`}{` is a trademark of Pegasys Labs.`}{`”`}</li>
        <li parentName="ul">{`You may not change the logo except to scale it. If the logo is used, you must give the Pegasys Labs logo adequate spacing from the other elements on the webpage or document to ensure legibility and reduce the likelihood of confusion.`}</li>
        <li parentName="ul">{`You must always use the wordmarks in their exact form and with the correct spelling, neither abbreviated, hyphenated, nor combined with any other word or words.`}</li>
        <li parentName="ul">{`You must always use the Pegasys Labs wordmarks only as an adjective, never as a noun or verb, or in plural or possessive forms.`}<ul parentName="li">
            <li parentName="ul">{`Instead, use the generic term for the Pegasys Labs product or service following the trademark.`}</li>
            <li parentName="ul">{`For example: Pegasys protocol, Pegasys interface, Pegasys website.`}</li>
          </ul></li>
      </ul>
      <p><strong parentName="p">{`All other uses of a Pegasys Labs trademark require our prior written permission.`}</strong>{` Contact us at `}<a parentName="p" {...{
          "href": "mailto:contact@pegasys.fi"
        }}>{`contact@pegasys.fi`}</a>{` for more information.`}</p>
      <h4 {...{
        "id": "unacceptable-uses",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#unacceptable-uses",
          "aria-label": "unacceptable uses permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a><strong parentName="h4">{`Unacceptable Uses`}</strong></h4>
      <h4 {...{
        "id": "some-specific-things-you-should-not-do-include",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h4" {...{
          "href": "#some-specific-things-you-should-not-do-include",
          "aria-label": "some specific things you should not do include permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "focusable": "false",
            "height": "16",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "16"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Some specific things you should not do include:`}</h4>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t use our trademarks in anything dishonest or fraudulent.`}</strong></li>
        <li parentName="ul"><strong parentName="li">{`Our name is not your name.`}</strong>{` Don`}{`’`}{`t use Pegasys Labs trademarks in the name of your smart contract, interface, business, product, service, app, domain name, publication, or other offering.`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t use our name for your products`}</strong>{`. Don`}{`’`}{`t apply Pegasys Labs trademarks to any product (e.g., a mobile app), unless such use is limited to a truthful and descriptive reference (e.g., `}{`”`}{`Mobile App for trading on Pegasys interface`}{`”`}{`).`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t overemphasize our mark.`}</strong>{` Don`}{`’`}{`t display Pegasys Labs trademarks more prominently than your product, service, or company name.`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t create or use confusingly similar names.`}</strong>{` Don`}{`’`}{`t use Pegasys Labs trademarks, logos, company names, slogans, domain names, or designs that are confusingly similar to Pegasys Labs trademarks, particularly in the name of your smart contract, interface, business, product, service, app, domain name, publication, or other offering.`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t imply our sponsorship of your products.`}</strong>{` Don`}{`’`}{`t use Pegasys Labs trademarks in a way that incorrectly implies affiliation with, sponsorship, endorsement, or approval by Pegasys Labs of your products or services.`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t imply our sponsorship of your activities.`}</strong>{` Don`}{`’`}{`t use Pegasys Labs trademarks and logos, or confusingly similar trademarks or logos on social media accounts in a way that might suggest affiliation with Pegasys Labs software; except if you`}{`’`}{`ve received prior permission from Pegasys Labs. For example, you cannot name your account, page, or community `}{`”`}{`Pegasys Protocol`}{`”`}{` or `}{`”`}{`Pegasys Team.`}{`”`}{` However, it would be acceptable to name your account, page, or community `}{`”`}{`Fans of Pegasys`}{`”`}{` or `}{`”`}{`Information about Pegasys`}{`”`}{`.`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t create swag with our marks.`}</strong>{` Don`}{`’`}{`t use Pegasys Labs trademarks on merchandise for sale (e.g., selling t-shirts, mugs, etc.) unless you have permission from Pegasys Labs.`}</li>
        <li parentName="ul"><strong parentName="li">{`Don`}{`’`}{`t change or combine our marks.`}</strong>{` Don`}{`’`}{`t modify Pegasys Labs trademarks, abbreviate them, or combine them with any other symbols, words, or images, or incorporate them into a tagline or slogan.`}</li>
      </ul>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      